import { IconType } from 'react-icons'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

type InfoProps = {
  children: React.ReactNode
  icon?: IconType
  className?: string
}

export default function Info({ children, icon, className = '' }: InfoProps) {
  const renderIcon = () => {
    return icon ? (
      icon({ className: 'h-5 w-5 text-brand-500', 'aria-hidden': true })
    ) : (
      <HiOutlineInformationCircle className="h-5 w-5 text-brand-500" aria-hidden="true" />
    )
  }

  return (
    <div className={classNames('rounded-md overflow-hidden', className)}>
      <div className="p-4 pr-12 bg-gray-50 border-l-4 border-brand-500 dark:bg-gray-300/5 dark:highlight">
        <div className="flex w-full">
          <div className="flex-shrink-0">{renderIcon()} </div>
          <div className="ml-3 flex-1 text-sm text-gray-700 dark:text-gray-400">{children}</div>
        </div>
      </div>
    </div>
  )
}
