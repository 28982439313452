/* eslint-disable @next/next/no-img-element */
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { HiPencil } from 'react-icons/hi'
import AssetMedia from 'components/AssetMedia'
import Info from './Info'
import SetAvatarBanner from './SetAvatarBanner'
import { getBannerURL } from 'helpers/banner'
import galleryImageLoader from 'helpers/galleryImageLoader'
import type { NfdRecord } from 'api/api-client'

const SimpleModal = dynamic(() => import('components/SimpleModal'))

interface ManageBannerProps {
  nfd: NfdRecord
  onUpload: (url: string) => void
}

export default function ManageBanner({ nfd }: ManageBannerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const renderModal = (triggerText: string) => {
    const AspectRatioInfo = () => (
      <div className="space-y-6 mt-6">
        <p>
          Your profile page banner can be any size, but it will be centered and cropped at a{' '}
          <span className="whitespace-nowrap">
            <strong className="font-medium text-gray-900 dark:text-gray-300">
              3:1 aspect ratio
            </strong>
            .
          </span>{' '}
          <span className="whitespace-nowrap">See below:</span>
        </p>
        <p className="hidden sm:block dark:hidden">
          <img src="/img/banner-aspect-ratio.svg" alt="" />
        </p>
        <p className="sm:hidden dark:hidden">
          <img src="/img/banner-aspect-ratio_mobile.svg" alt="" />
        </p>
        <p className="hidden dark:sm:block">
          <img src="/img/banner-aspect-ratio-dark.svg" alt="" />
        </p>
        <p className="hidden dark:block dark:sm:hidden">
          <img src="/img/banner-aspect-ratio-dark_mobile.svg" alt="" />
        </p>
        <p>
          It will also be resized to fit or fill this space, as needed. We recommend an image that
          is at least{' '}
          <strong className="font-medium text-gray-900 dark:text-gray-300">
            1500px &times; 500px
          </strong>{' '}
          for the <span className="whitespace-nowrap">best quality.</span>
        </p>
      </div>
    )

    return (
      <SimpleModal
        triggerText={triggerText}
        triggerClassName="underline cursor-help dark:no-underline"
        title="Recommended banner size"
        content={<AspectRatioInfo />}
        className="max-w-2xl"
      />
    )
  }

  return (
    <div>
      <div className="mt-1 space-y-5 lg:hidden">
        <div className="relative w-full aspect-[3/1]">
          <AssetMedia
            src={getBannerURL(nfd)}
            alt={`${nfd.name} banner`}
            className="object-cover w-full h-full"
            loader={galleryImageLoader}
            priority
            sizes="(max-width: 640px) 100vw, 600px"
            fill
            options={{
              defaultImg: '/img/nfd-banner-placeholder.jpg'
            }}
          />
        </div>
        <div>
          <button
            onClick={() => setIsOpen(true)}
            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-brand-500"
          >
            <span>Change</span>
            <span className="sr-only"> NFD banner</span>
          </button>
        </div>
      </div>

      <div className="hidden relative lg:block lg:mt-1 w-full aspect-[3/1]">
        <button onClick={() => setIsOpen(true)}>
          <AssetMedia
            src={getBannerURL(nfd)}
            alt={`${nfd.name} banner`}
            className="object-cover w-full h-full"
            loader={galleryImageLoader}
            priority
            sizes="600px"
            fill
            options={{
              defaultImg: '/img/nfd-banner-placeholder.jpg'
            }}
          />
          <div className="absolute inset-0 w-full h-full bg-black bg-opacity-25 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100 min-h-[5em]">
            <span className="inline-flex items-center p-3 rounded-full shadow-sm text-white bg-gray-900/75 hover:bg-gray-900/50">
              <HiPencil className="h-6 w-6" aria-hidden="true" />
            </span>
            <span className="sr-only">Upload NFD banner</span>
          </div>
        </button>
      </div>
      <Info className="mt-5 lg:mt-3">
        <p className="font-medium">
          Recommended banner size is {renderModal('3:1 aspect ratio')}, and at least
          1500px&nbsp;wide
        </p>
      </Info>
      <SetAvatarBanner field="banner" nfd={nfd} isOpen={isOpen} setIsOpen={handleSetIsOpen} />
    </div>
  )
}
