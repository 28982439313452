import { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { CSSTransition } from 'react-transition-group'
import AssetMedia from 'components/AssetMedia'
import galleryImageLoader from 'helpers/galleryImageLoader'
import type { Asset } from '../Gallery.types'

interface AssetPreviewProps {
  asset: Asset
  onSelectAsset: (asset: Asset) => void
  isInVault?: boolean
}

export default function AssetPreview({ asset, onSelectAsset, isInVault }: AssetPreviewProps) {
  const badgeRef = useRef<HTMLSpanElement>(null)

  return (
    <div>
      <button
        type="button"
        className="overflow-hidden hover:scale-[1.02] transition hover:shadow-md origin-bottom w-full bg-black/5 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 [-webkit-transform-style:preserve-3d] rounded-lg dark:bg-white/5 dark:ring-offset-gray-850"
        onClick={() => onSelectAsset(asset)}
      >
        <div className="relative aspect-square overflow-hidden">
          <AssetMedia
            src={asset.imageUrl}
            alt={asset.asaID.toString()}
            className="object-cover w-full h-full"
            loader={galleryImageLoader}
            sizes="(max-width: 640px) 100vw, 360px"
            fill
            options={{ optimizeGifs: true, stateBgColor: 'transparent' }}
            videoJsOptions={{
              preload: isMobile ? 'auto' : 'metadata',
              controls: isMobile,
              fluid: true,
              fill: true
            }}
          />

          {/* @ts-expect-error Not a valid JSX element */}
          <CSSTransition
            in={isInVault}
            nodeRef={badgeRef}
            timeout={500}
            classNames="vault-badge"
            unmountOnExit
          >
            <div className="absolute top-1 right-2">
              <span
                ref={badgeRef}
                className="inline-flex items-center rounded-md bg-brand-500 px-1.5 py-0.5 text-xs font-medium text-white"
              >
                Vaulted
              </span>
            </div>
          </CSSTransition>
        </div>
      </button>

      <div className="flex-1 flex items-center space-x-1 mt-2 min-w-0">
        <h3 className="text-sm font-medium text-gray-900 truncate dark:text-gray-200">
          {asset.name}
        </h3>
      </div>
    </div>
  )
}
