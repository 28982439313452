import { useWallet } from '@txnlab/use-wallet-react'
import { useState } from 'react'
import { usePatchUpdateProperties } from 'api/hooks/usePatchUpdateProperties'
import useVerifyConfirm from 'api/hooks/useVerifyConfirm'
import { useNameUpdate } from 'api/hooks/useName'
import { nfdVerifyRequest } from 'api/api-client'
import useErrorToast from 'hooks/useErrorToast'
import { updateNfdProperties } from 'helpers/optimisticUpdates'
import { capitalizeFirstLetter } from 'helpers/strings'
import type { Field } from './SetAvatarBanner.types'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from 'components/manage/Gallery/Gallery.types'

export default function useSetAvatarBanner(nfd: NfdRecord, field: Field, setIsOpen: () => void) {
  const [asset, setAsset] = useState<Asset>()
  const [isUpdating, setIsUpdating] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [fullWidth, setFullWidth] = useState(false)
  const [steps, setSteps] = useState<{ id: string; name: string }[]>()

  const { activeAccount } = useWallet()
  const handleError = useErrorToast()

  const optimisticUpdate = useNameUpdate()

  const { mutateAsync: updateResetToDefault } = usePatchUpdateProperties({
    toasts: {
      success: `${capitalizeFirstLetter(field)} successfully reset to default`
    },
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticUpdate(newNfd)
    }
  })

  const { mutateAsync: updateField } = usePatchUpdateProperties({
    toasts: {
      success: `${capitalizeFirstLetter(field)} successfully updated`
    },
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticUpdate(newNfd)
    }
  })

  const { mutateAsync: submitVerifyConfirm } = useVerifyConfirm({
    loading: `Verifying ${field}...`,
    success: `${capitalizeFirstLetter(field)} successfully verified!`,
    error: `Verification failed`
  })

  const handleClickSetNFT = () => {
    setFullWidth(true)
    setSteps([
      { id: 'select', name: `Select ${field}` },
      { id: 'verify', name: `Verify ${field}` }
    ])
  }

  const handleSelectNFT = (asset: Asset) => {
    setAsset(asset)
  }

  const handleClickSet = async () => {
    if (!field) {
      throw new Error('Field not set')
    }

    if (!asset) {
      throw new Error('Asset not set')
    }

    setIsUpdating(false)
    setFullWidth(false)
    setCurrentStep((currentStep) => currentStep + 1)
  }

  const handleClickGoBack = () => {
    setCurrentStep(0)
    setSteps(undefined)
    setFullWidth(false)
    setAsset(undefined)
    setIsUpdating(false)
  }

  const handleClickResetToDefault = async () => {
    setIsUpdating(true)

    if (!field) {
      throw new Error('Field not set')
    }

    const body = {
      sender: activeAccount?.address as string,
      properties: {}
    }

    if (nfd?.properties?.userDefined?.[field]) {
      body.properties = {
        userDefined: {
          [field]: ''
        }
      }
    }

    if (nfd?.properties?.verified?.[field]) {
      body.properties = {
        ...body.properties,
        verified: {
          [field]: ''
        }
      }
    }

    try {
      await updateResetToDefault({ name: nfd.name, body })
    } catch (e) {
      handleError(e)
    } finally {
      reset()
      setIsOpen()
    }
  }

  const handleClickVerify = async () => {
    setIsUpdating(true)
    if (!field) {
      throw new Error('Field not set')
    }

    if (!asset) {
      throw new Error('Asset not set')
    }

    const body = {
      sender: activeAccount?.address as string,
      properties: {
        userDefined: { [field]: asset?.asaID.toString() as string }
      }
    }

    try {
      await updateField({ name: nfd.name, body })
    } catch (e) {
      handleError(e)
      reset()
    }

    const { data } = await nfdVerifyRequest({
      name: nfd.name,
      fieldToVerify: field,
      sender: activeAccount?.address as string
    })

    if (data.id === undefined || data.challenge === undefined) {
      throw new Error('Verification request failed.')
    }

    try {
      await submitVerifyConfirm({
        id: data.id,
        challenge: data.challenge,
        name: nfd.name,
        field,
        asset
      })
    } catch (e) {
      handleError(e)
    } finally {
      reset()
      setIsOpen()
    }
  }

  const reset = () => {
    setIsOpen()
    setCurrentStep(0)
    setSteps(undefined)
    setFullWidth(false)
    setAsset(undefined)
    setIsUpdating(false)
  }

  const handleUploadComplete = () => {
    reset()
  }

  return {
    asset,
    isUpdating,
    currentStep,
    fullWidth,
    steps,
    handleClickSetNFT,
    handleSelectNFT,
    handleClickSet,
    handleClickGoBack,
    handleClickVerify,
    handleUploadComplete,
    handleClickResetToDefault,
    reset
  }
}
