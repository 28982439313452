import { type NfdRecord, nfdVaultOptInLock, type VaultOptInLockRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type VaultLockParams = {
  name: string
  body: VaultOptInLockRequestBody
}

type VaultLockContext = { prevNfd?: NfdRecord; newNfd?: NfdRecord } | undefined

type VaultLockOptions = MutationOptions<VaultLockParams, VaultLockContext>

export function usePostVaultOptInLock(options: VaultLockOptions = {}) {
  return usePostTransaction<VaultLockParams, VaultLockContext>({
    mutationFn: ({ name, body }) => nfdVaultOptInLock(name, body),
    ...options
  })
}
