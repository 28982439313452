import { Fragment } from 'react'
import { InView } from 'react-intersection-observer'
import { HiSearch, HiX } from 'react-icons/hi'
import Alert from 'components/Alert'
import ItemLayout from 'components/ItemLayout'
import Loading from 'components/Loading'
import AssetPreview from './AssetPreview'
import useGallery from './Gallery.hooks'
import type { Asset } from './Gallery.types'
import type { NfdRecord } from 'api/api-client'

type GalleryProps = {
  nfd: NfdRecord
  onSelectAsset: (asset: Asset) => void
}

export default function Gallery({ nfd, onSelectAsset }: GalleryProps) {
  const {
    nfts,
    isLoading,
    error,
    query,
    handleQueryChange,
    clearQuery,
    fetchNextPage,
    isFetchingNextPage
  } = useGallery(nfd)

  const renderResult = (results: Asset[], hasNextPage: number | null, pageIdx: number) => {
    if (!nfts) {
      return null
    }

    return results.map((asset: Asset, i) => {
      const isInVault = asset.foundIn === nfd.nfdAccount

      if (i === results.length - 1) {
        // last element in the page

        if (pageIdx === nfts.pages.length - 1 && hasNextPage) {
          // this is last page, and there is a next page

          return (
            <InView key={asset.asaID} onChange={(inView) => inView && fetchNextPage()}>
              {({ ref }) => (
                <div ref={ref}>
                  <AssetPreview asset={asset} onSelectAsset={onSelectAsset} isInVault={isInVault} />
                </div>
              )}
            </InView>
          )
        }
      }

      return (
        <AssetPreview
          key={asset.asaID}
          asset={asset}
          onSelectAsset={onSelectAsset}
          isInVault={isInVault}
        />
      )
    })
  }

  const renderGallery = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center py-32">
          <Loading />
        </div>
      )
    }

    if (error) {
      return <Alert type="error" title={`Error fetching gallery assets`} error={error} />
    }

    if (!nfts?.pages[0]?.results?.length) {
      return <Alert type="info" title="No NFTs found in linked accounts" />
    }

    return (
      <>
        <ItemLayout view="grid">
          {nfts?.pages.map((page, pageIdx) => {
            return (
              <Fragment key={pageIdx}>
                {renderResult(page.results, page.nextPage, pageIdx)}
              </Fragment>
            )
          })}
        </ItemLayout>

        {isFetchingNextPage && (
          <div className="w-full flex flex-col items-center justify-center py-16">
            <Loading />
          </div>
        )}
      </>
    )
  }

  return (
    <div className="bg-white border-t border-gray-200 sm:rounded-lg sm:border-none dark:bg-gray-900 dark:border-gray-800">
      <div className="pt-6 pb-4">
        <div className="relative rounded-md shadow-sm sm:max-w-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <HiSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="gallery-search"
            id="gallery-search"
            className="focus:ring-brand-500 focus:border-brand-500 block w-full px-10 sm:text-sm border-gray-300 rounded-md dark:bg-gray-800 dark:border-transparent dark:focus:border-brand-500 dark:text-gray-100 dark:placeholder-gray-500 dark:caret-gray-400"
            placeholder="Search name or ASA ID"
            value={query}
            onChange={handleQueryChange}
          />
          {query.length > 0 && (
            <div className="absolute inset-y-0 right-2 flex items-center">
              <button
                type="button"
                className="inline-flex items-center border border-transparent rounded-full text-gray-400 bg-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                onClick={clearQuery}
              >
                <HiX className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="py-5 h-[calc(100vh_-_25rem)] sm:h-[calc(100vh_-_21rem)] overflow-scroll">
        {renderGallery()}
      </div>
    </div>
  )
}
