/* eslint-disable @next/next/no-img-element */
import { useState } from 'react'
import { HiPencil } from 'react-icons/hi'
import AssetMedia from 'components/AssetMedia'
import SetAvatarBanner from './SetAvatarBanner'
import { getAvatarURL } from 'helpers/avatar'
import galleryImageLoader from 'helpers/galleryImageLoader'
import type { NfdRecord } from 'api/api-client'

interface ManageAvatarProps {
  nfd: NfdRecord
  onUpload: (url: string) => void
}

export default function ManageAvatar({ nfd }: ManageAvatarProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <div className="mt-1 flex items-center space-x-5 lg:hidden">
        <span className="relative inline-block mt-1 h-40 w-40 rounded-full overflow-hidden bg-gray-100">
          <AssetMedia
            src={getAvatarURL(nfd)}
            alt={`${nfd.name} avatar`}
            className="object-cover w-full h-full"
            loader={galleryImageLoader}
            sizes="160px"
            fill
          />
        </span>
        <button
          onClick={() => setIsOpen(true)}
          className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-brand-500"
        >
          <span>Change</span>
          <span className="sr-only">Set NFD avatar</span>
        </button>
      </div>

      <div className="hidden relative rounded-full overflow-hidden mt-1 lg:block aspect-square">
        <button onClick={() => setIsOpen(true)}>
          <AssetMedia
            src={getAvatarURL(nfd)}
            alt={`${nfd.name} avatar`}
            className="object-cover w-full h-full"
            loader={galleryImageLoader}
            sizes="300px"
            fill
          />
          <div className="absolute inset-0 w-full h-full bg-black bg-opacity-25 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100 min-h-[5em]">
            <span className="inline-flex items-center p-3 rounded-full shadow-sm text-white bg-gray-900/75 hover:bg-gray-900/50">
              <HiPencil className="h-6 w-6" aria-hidden="true" />
            </span>
            <span className="sr-only">Upload NFD avatar</span>
            <div className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" />
          </div>
        </button>
      </div>
      <SetAvatarBanner field="avatar" nfd={nfd} isOpen={isOpen} setIsOpen={handleSetIsOpen} />
    </div>
  )
}
