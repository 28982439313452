import dynamic from 'next/dynamic'
import UploadFile from '../UploadFile'
import SetNFT from '../SetNFT'
import ResetToDefault from '../ResetToDefault'
import Step from 'components/Step'
import Gallery from 'components/manage/Gallery'
import Button from 'components/Button'
import VerifiedBadge from 'components/VerifiedBadge'
import AssetMedia from 'components/AssetMedia'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { classNames } from 'helpers/utilities'
import useSetAvatarBanner from './SetAvatarBanner.hooks'
import { ICONS } from './SetAvatarBanner.constants'
import { Field } from './SetAvatarBanner.types'
import type { NfdRecord } from 'api/api-client'

const Modal = dynamic(() => import('components/Modal'))

type SetAvatarBannerProps = {
  nfd: NfdRecord
  field: Field
  isOpen: boolean
  setIsOpen: () => void
}

export default function SetAvatarBanner({ nfd, isOpen, setIsOpen, field }: SetAvatarBannerProps) {
  const {
    asset,
    isUpdating,
    currentStep,
    fullWidth,
    steps,
    handleClickSetNFT,
    handleSelectNFT,
    handleClickSet,
    handleClickGoBack,
    handleClickVerify,
    handleUploadComplete,
    handleClickResetToDefault,
    reset
  } = useSetAvatarBanner(nfd, field, setIsOpen)

  const renderSteps = () => {
    return steps?.map(({ id: stepId }, index) => {
      return (
        <div key={`${stepId}-${index}`}>
          <Step currentStep={currentStep} step={index}>
            {stepId === 'select' && (
              <div>
                <Gallery nfd={nfd} onSelectAsset={handleSelectNFT} />
                <div className="flex justify-center mt-8 capitalize space-x-4">
                  <Button variant="default" onClick={handleClickGoBack}>
                    <p className="capitalize">Go back</p>
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleClickSet}
                    disabled={isUpdating || !asset}
                  >
                    <p className="capitalize">{`Select ${field}`}</p>
                  </Button>
                </div>
              </div>
            )}
            {stepId === 'verify' && (
              <div>
                <p className="text-sm text-gray-500 break-words mb-6">
                  Prove you own your{' '}
                  <span className="font-semibold text-gray-600 dark:text-gray-400">{field}</span> by
                  verifying it!
                </p>
                <div className="flex justify-center items-center w-full">
                  <div className="relative aspect-square w-24 h-24 shadow-lg rounded-lg overflow-hidden">
                    <AssetMedia
                      src={asset?.imageUrl || ''}
                      alt={asset?.asaID.toString() || ''}
                      className="object-cover w-full h-full"
                      loader={galleryImageLoader}
                      width={100}
                      height={100}
                    />
                    <div className="absolute inset-0 rounded-lg overflow-hidden">
                      <div className="block absolute bottom-0 right-0 -mr-12 -mb-12">
                        <div>
                          <VerifiedBadge />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm ml-4 text-left space-y-2 flex flex-col justify-center">
                    <div>
                      <p className="font-medium text-gray-700 dark:text-gray-300">Name</p>
                      <p className="text-gray-500 truncate">{asset?.name}</p>
                    </div>
                    <div>
                      <p className="font-medium text-gray-700 dark:text-gray-300">Asset ID</p>
                      <p className="text-gray-500 truncate">{asset?.asaID}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-8 capitalize space-x-4">
                  <Button variant="default" onClick={handleClickGoBack}>
                    <p className="capitalize">Go back</p>
                  </Button>
                  <Button variant="primary" onClick={handleClickVerify} disabled={isUpdating}>
                    <p className="capitalize">
                      {isUpdating ? `Verifying ${field}...` : `Verify ${field}`}
                    </p>
                  </Button>
                </div>
                <div className="mt-6 capitalize"></div>
              </div>
            )}
          </Step>
        </div>
      )
    })
  }

  const renderButtons = () => {
    const hasFieldSet = nfd.properties?.userDefined?.[field] || nfd.properties?.verified?.[field]

    return (
      <div className="py-4 sm:py-6 space-y-6">
        <div>
          <UploadFile nfd={nfd} field={field} onComplete={handleUploadComplete} />
        </div>
        <div>
          <SetNFT onClick={handleClickSetNFT} />
        </div>
        {hasFieldSet && (
          <div>
            <ResetToDefault onClick={handleClickResetToDefault} field={field} />
          </div>
        )}
      </div>
    )
  }

  return (
    <Modal
      manualClose={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className={classNames(fullWidth ? 'h-[calc(100vh_-_5rem)] max-w-screen-2xl' : '')}
      onClose={reset}
      icon={field ? ICONS[field] : ICONS.default}
      title={`Update ${field ? field : ''}`}
    >
      {!steps && renderButtons()}
      {steps && <div className="mt-6">{renderSteps()}</div>}
    </Modal>
  )
}
