import { useEffect, useState } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { useInfiniteQuery } from '@tanstack/react-query'
import { NfdRecord } from 'src/api/api-client'
import { API_BASE_URL } from 'src/data/constants'
import { topBarHeight } from 'src/data/layout'
import useDebounce from 'hooks/useDebounce'
import type { Asset, PagedGalleryData, UseGalleryLimitArgs } from './Gallery.types'

export default function useGallery(nfd: NfdRecord) {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const clearQuery = () => {
    setQuery('')
  }

  const fetchNfts = async (page: number, limit: number) => {
    let results: AxiosResponse<Asset[], unknown>
    let filteredResults: Asset[] = []
    let fetchedPage = page

    results = await axios.get<Asset[]>(
      `${API_BASE_URL}/nfd/nfts/${nfd.name}?limit=${limit}&offset=${(fetchedPage - 1) * limit}${
        debouncedQuery !== '' ? `&q=${debouncedQuery}` : ''
      }`
    )

    const filterResults = (asset: Asset) => {
      const isBelowSupplyThreshold = asset.totalCreated / Math.pow(10, asset.decimals) <= 10000

      return isBelowSupplyThreshold && asset.unitName?.toLowerCase() !== 'nfd'
    }

    filteredResults = results.data.filter(filterResults)

    while (results.data.length > 0 && filteredResults.length === 0) {
      fetchedPage++
      results = await axios.get<Asset[]>(
        `${API_BASE_URL}/nfd/nfts/${nfd.name}?limit=${limit}&offset=${(fetchedPage - 1) * limit}${
          debouncedQuery !== '' ? `&q=${debouncedQuery}` : ''
        }`
      )

      filteredResults.push(...results.data.filter(filterResults))
    }

    if (!results.data.length) {
      return {
        results: [],
        nextPage: null
      }
    }

    return {
      results: filteredResults,
      nextPage: results.data.length === limit ? fetchedPage + 1 : null
    }
  }

  const { limit, enabled } = useGalleryLimit()

  const {
    data: nfts,
    isInitialLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery<PagedGalleryData, AxiosError>(
    ['nfts-avatar-banner', nfd.name, { query: debouncedQuery, limit }],
    ({ pageParam = 1 }) => fetchNfts(pageParam, limit),
    {
      enabled,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextPage
    }
  )

  return {
    nfts,
    isLoading: isInitialLoading,
    error,
    query,
    handleQueryChange,
    clearQuery,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch
  }
}

export const useGalleryLimit = (options?: UseGalleryLimitArgs) => {
  const { min = 6, max = 20 } = options || {}

  const [limit, setLimit] = useState<number | null>(null)

  const getNumResultsPerPage = () => {
    const isSmScreen = window.matchMedia('(min-width: 640px)').matches
    const isMdScreen = window.matchMedia('(min-width: 768px)').matches
    const isXlScreen = window.matchMedia('(min-width: 1280px)').matches

    const viewportHeight = window.innerHeight || document.documentElement.clientHeight
    const filtersHeight = 116
    const stickyContentHeight = topBarHeight + filtersHeight
    const containerMaxVisibleHeight = Math.max(viewportHeight - stickyContentHeight, 0)
    const containerPaddingTop = 48 // padding-top of container
    const availableHeight = containerMaxVisibleHeight - containerPaddingTop

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth
    const sidebarWidth = 256
    const availableWidth = viewportWidth - sidebarWidth

    const xlGridGapX = 32
    const xlMinElementWidth = 240
    const xlContainerPaddingX = 32
    const xlMaxResultsPerRow = Math.floor(
      (availableWidth - xlContainerPaddingX) / (xlMinElementWidth + xlGridGapX)
    )

    const resultsPerRow = isXlScreen ? xlMaxResultsPerRow : isMdScreen ? 3 : isSmScreen ? 2 : 1

    const rowPaddingY = isMdScreen ? 40 : 16
    const minElementHeight = isMdScreen ? 280 : isSmScreen ? 333 : 388
    const minRowHeight = minElementHeight + rowPaddingY

    const minVisibleRows = Math.ceil(availableHeight / minRowHeight)
    const minVisibleResults = minVisibleRows * resultsPerRow

    return minVisibleResults
  }

  useEffect(() => {
    if (limit === null) {
      const numResultsPerPage = Math.max(getNumResultsPerPage(), min)
      setLimit(numResultsPerPage)
    }
  }, [limit, min])

  const getReturnedLimit = () => {
    const limitNum = Number(limit)

    return Math.min(max, Math.max(min, limitNum))
  }

  return {
    enabled: limit !== null,
    limit: getReturnedLimit()
  }
}
