import { IconType } from 'react-icons'

type StepProps = {
  step: number | string
  currentStep: number | string
  header?: string
  description?: string
  icon?: IconType
  children: React.ReactNode
}

export default function Step({
  step,
  icon,
  currentStep,
  header,
  description,
  children
}: StepProps) {
  if (step !== currentStep) {
    return null
  }

  const renderHeader = () => {
    return (
      header && (
        <p className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{header}</p>
      )
    )
  }

  const renderDescription = () => {
    return (
      description && (
        <div className="mt-2">
          <p className="text-sm text-gray-500 break-words">{description}</p>
        </div>
      )
    )
  }

  const renderIcon = () => {
    return (
      icon && (
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 dark:bg-gray-300/10">
          {icon({ className: 'h-6 w-6 text-brand-600 dark:text-brand-500' })}
        </div>
      )
    )
  }

  return (
    <div>
      <div>
        {renderIcon()}
        <div className="mt-2 text-center sm:mt-2">
          {renderHeader()}
          {renderDescription()}
          <div className="mt-6">{children}</div>
        </div>
      </div>
    </div>
  )
}
