import { useWallet } from '@txnlab/use-wallet-react'
import { useState, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { usePatchUpdateProperties } from 'api/hooks/usePatchUpdateProperties'
import { useNameUpdate } from 'api/hooks/useName'
import { updateNfdProperties } from 'helpers/optimisticUpdates'
import { capitalizeFirstLetter } from 'helpers/strings'
import type { NfdRecord } from 'api/api-client'

export default function useUploadFile(
  nfd: NfdRecord,
  field: 'avatar' | 'banner',
  onComplete: () => void
) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const { activeAddress } = useWallet()

  const optimisticUpdate = useNameUpdate()

  const { mutateAsync: updateFileUrl } = usePatchUpdateProperties({
    toasts: {
      success: `${capitalizeFirstLetter(field)} successfully updated!`
    },
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticUpdate(newNfd)
    }
  })

  // file inputs
  const inputRef = useRef<HTMLInputElement>(null)
  // hidden form submit button
  const buttonRef = useRef<HTMLButtonElement>(null)

  // automatically submit form when file is selected
  useEffect(() => {
    if (selectedFile && buttonRef?.current) {
      buttonRef.current.click()
    }
  }, [selectedFile])

  const handleChangeFile = (file: File | null) => {
    setSelectedFile(file)
  }

  const handleError = (errorMessage: string, toastId?: string) => {
    toast.error(errorMessage, {
      id: toastId
    })
    reset()
  }

  const reset = () => {
    setSelectedFile(null)

    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const handleSubmitFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!selectedFile) {
      handleError('No file selected.')
      return
    }

    if (!selectedFile.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      handleError('Uploaded image must be JPG, PNG, or GIF format. Please select another image.')
      return
    }

    const fileSize = selectedFile.size / 1024 / 1024
    if (fileSize > 5) {
      handleError('Uploaded image must be less than 5 MB. Please select another image.')
      return
    }

    const toastId = toast.loading(`Uploading ${field}`)

    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      const request = new Request(
        `${process.env.NEXT_PUBLIC_API}/nfd/updateImage/${nfd.name}/${nfd.owner}/${field}?wantTxn=false`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/octet-stream' },
          body: selectedFile
        }
      )

      const response = await fetch(request)

      toast.dismiss(toastId)

      if (response.status === 200) {
        const url = await response.json()

        await updateFileUrl({
          name: nfd?.name as string,
          body: {
            sender: activeAddress,
            properties: {
              userDefined: { [field]: url }
            }
          }
        })
      } else {
        throw new Error(response.statusText)
      }

      // reset file inputs
      reset()
      onComplete()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      handleError(`Error uploading ${field}${err?.message ? `: ${err.message}` : ''}`, toastId)
    }
  }

  return { handleChangeFile, handleSubmitFile, inputRef, buttonRef }
}
