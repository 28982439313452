import useUploadFile from './UploadFile.hooks'
import type { NfdRecord } from 'api/api-client'
import { HiUpload } from 'react-icons/hi'

type UploadFileProps = {
  nfd: NfdRecord
  field: 'avatar' | 'banner'
  onComplete: () => void
}

export default function UploadFile({ nfd, field, onComplete }: UploadFileProps) {
  const { inputRef, buttonRef, handleSubmitFile, handleChangeFile } = useUploadFile(
    nfd,
    field,
    onComplete
  )

  return (
    <form onSubmit={handleSubmitFile}>
      <div className="w-full flex align-middle justify-center mt-1 p-5 border-2 border-gray-100 rounded-md dark:bg-gray-850 dark:border-none">
        <div className="flex flex-col align-middle justify-center space-y-1 text-center">
          <div className="flex justify-center items-center flex-col">
            <label
              htmlFor="file-upload"
              className="flex items-center text-md text-center pr-1 cursor-pointer rounded-md font-medium text-brand-600 hover:text-brand-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-500 dark:text-brand-500 dark:hover:text-brand-600 dark:focus-within:ring-offset-gray-900 dark:focus-within:ring-gray-100"
            >
              <HiUpload className="h-5 w-5 mr-2" />
              <span>Upload a file</span>
              <input
                ref={inputRef}
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={(event) => handleChangeFile(event.target.files?.[0] || null)}
              />
            </label>
          </div>
          <p className="text-xs text-gray-500">Upload an image. The maximum file size is 5MB.</p>
        </div>
      </div>
      <button ref={buttonRef} type="submit" className="hidden" />
    </form>
  )
}
