import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useNameUpdate } from 'api/hooks/useName'
import { CustomError } from 'api/customError'
import { retry } from 'helpers/utilities'
import { isLegacyAsset } from 'helpers/node'
import { updateNfdProperties } from 'helpers/optimisticUpdates'
import {
  type NFDPropertiesVerified,
  type NfdRecord,
  nfdVerifyConfirm,
  type VerifyConfirmResponseBody
} from 'api/api-client'
import type { AsaInfo } from 'types/node'
import type { Asset } from 'types/assets'

const TOAST_ID = 'verify-confirm'

export const verifyConfirm = async (id: string, challenge: string) => {
  const { data } = (await nfdVerifyConfirm(id, { challenge }).catch((error) => {
    // retry on 5xx
    if (error instanceof CustomError && error.statusCode >= 500) {
      return retry(() => nfdVerifyConfirm(id, { challenge }))
    } else {
      throw error
    }
  })) as AxiosResponse<VerifyConfirmResponseBody, CustomError>

  return data
}

type UseVerifyConfirm = {
  loading: string
  success: string
  error: string
}

type VerifyConfirmArgs = {
  id: string
  challenge: string
  name: string
  field?: string
  asset?: AsaInfo | Asset // @todo: remove AsaInfo when we remove support for legacy assets
}

export default function useVerifyConfirm(options: UseVerifyConfirm) {
  const queryClient = useQueryClient()
  const optimisticUpdate = useNameUpdate()

  return useMutation(
    ({ id, challenge }: VerifyConfirmArgs) => {
      return verifyConfirm(id, challenge)
    },
    {
      onMutate: () => {
        toast.loading(options.loading, { id: TOAST_ID })
      },
      onSuccess: (data, params) => {
        toast.success(options.success, {
          id: TOAST_ID,
          duration: 5000 // 5 seconds
        })

        queryClient.invalidateQueries({ queryKey: ['name', params.name] })

        if (!params.field) return

        const prevNfd: NfdRecord | undefined = queryClient.getQueryData(['name', params.name])

        if (!prevNfd?.properties?.userDefined) return
        const fieldValue = prevNfd.properties.userDefined[params.field]

        let verified: NFDPropertiesVerified = {}

        if (params.field === 'avatar' || params.field === 'banner') {
          if (!params.asset) return

          verified = {
            [params.field]: params.asset.imageUrl,
            // @todo: remove this check when we remove support for legacy assets
            [params.field + 'asaid']: isLegacyAsset(params.asset)
              ? params.asset.asaID.toString()
              : params.asset.id.toString()
          }
        } else {
          if (!fieldValue) return
          verified = { [params.field]: fieldValue }
        }

        const newNfd = updateNfdProperties(prevNfd, {
          userDefined: {
            [params.field]: ''
          },
          verified
        })

        optimisticUpdate(newNfd)
      },
      onError: () => {
        toast.dismiss(TOAST_ID)
      }
    }
  )
}
