import { HiCursorClick } from 'react-icons/hi'

type SetNFTProps = {
  onClick: () => void
}

export default function SetNFT({ onClick }: SetNFTProps) {
  return (
    <div>
      <div className="w-full flex align-middle justify-center mt-1 p-5 border-2 border-gray-100 rounded-md dark:bg-gray-850 dark:border-none">
        <div className="flex flex-col align-middle justify-center space-y-1 text-center">
          <div className="flex justify-center items-center flex-col">
            <button
              onClick={onClick}
              className="flex items-center text-md text-center pr-1 cursor-pointer rounded-md font-medium text-brand-600 hover:text-brand-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-500 dark:text-brand-500 dark:hover:text-brand-600 dark:focus-within:ring-offset-gray-900 dark:focus-within:ring-gray-100"
            >
              <HiCursorClick className="h-5 w-5 mr-2" />
              <span>Select an NFT</span>
            </button>
          </div>
          <p className="text-xs text-gray-500">Select an NFT from your linked accounts.</p>
        </div>
      </div>
    </div>
  )
}
