import { BsImage } from 'react-icons/bs'
import { HiUserCircle, HiOutlineCheckCircle } from 'react-icons/hi'
import type { IconType } from 'react-icons'
import { Field } from './SetAvatarBanner.types'

export const ICONS: { [key in Field | 'default']: IconType } = {
  avatar: HiUserCircle,
  banner: BsImage,
  default: HiOutlineCheckCircle
}
